<template>
  <div class="ma-3 blogcard" v-if="source !== null">
    <DragDropFile
      :item="source"
      :editor="editormultiple"
      :onItem="true"
      :allowdrop="authorization.update"
      :authorization="authorization"
      @dropText="dropText"
      :showText="false"
      class="full-width"
      ref="dragdrop"
    >
      <div :id="'blog' + source._id" class="scroll-mrg"></div>
      <div
        class="navigate card"
        :id="source._id"
        :class="highlighted ? 'selected' : ''"
        v-if="!expand"
      >
        <div class="pa-4 blog-content"  :class="[{ dragicon: authorization.update }]">
          <v-row no-gutters class="blogclick">
            <div
              class="heading-left"
              :class="isMobile ? '' : 'd-flex'"
              @click="highlightSelected(source)"
            >
              <div class="content-left">
                <h2
                  :class="isMobile ? 'text-center' : ''"
                  style="font-size: 1.3rem"
                  class="custom-input font-weight-bold h1 text-left pa-1 mt-0"
                >
                  {{ source.title }}
                </h2>
                <div v-if="source.createdBy">
                  <v-avatar size="40" color="orange accent-1">
                    <AsyncAvatarImage
                      :username="source.createdBy"
                      :collection="collection"
                    />
                  </v-avatar>
                  <span class="ml-3 title" v-if="source.createdBy">{{
                    userInfo.display
                  }}</span>
                </div>
              </div>
              <div class="content-right">
                <p
                  class="
                    px-3
                    pt-1
                    caption
                    float-none float-md-right float-sm-none
                  "
                  v-if="source.createdAt"
                >
                  <v-icon small>mdi-history</v-icon>
                  Posted on:
                  {{
                    utils.formatDateTime(acctualPostDate, "DD/MMM/YYYY hh:mm A")
                  }}
                </p>
              </div>
            </div>
            <div class="heading-right" v-if="!isMobile">
              <div class="pa-0">
                <v-btn @click="openItemEditor(source)" icon
                  ><v-icon class="small-icon primary--text"
                    >mdi-arrow-expand</v-icon
                  ></v-btn
                >
                <div
                  class="mt-2 comment-box"
                  @click="toggleCollapseComment = !toggleCollapseComment"
                >
                  <div v-if="comments">
                    <v-icon class="small-icon primary--text"
                      >mdi-comment-text-outline</v-icon
                    ><span class="item-font">{{ comments }}</span>
                  </div>
                  <div v-else>
                    <v-icon class="small-icon primary--text"
                      >mdi-comment-plus-outline</v-icon
                    >
                  </div>
                </div>
                <div
                  class="mt-2 attachment-box"
                  @click="
                    toggleCollapseAttachment = !toggleCollapseAttachment;
                    showAttachments();
                  "
                >
                  <v-icon class="small-icon primary--text"
                    >mdi-attachment</v-icon
                  ><span class="item-font" v-if="attachments">{{
                    attachments
                  }}</span>
                </div>
              </div>
            </div>
          </v-row>
          <v-row
            no-gutters
            class="mt-4"
            @click="highlightSelected(source)"
            style="min-height: 200px"
          >
            <v-col cols="12" sm="12" md="12" class="blogclick column">
              <div
                class="blogclick text-center mb-3"
                :class="[
                  index % 2 == 0
                    ? 'float-md-left float-sm-none margin-right'
                    : 'float-md-right float-sm-none margin-left',
                  isMobile ? 'ma-0' : '',
                ]"
                @click="openItem(source, index)"
                v-show="!imageError"
              >
                <FeatureImage
                  :attachmentView="attachmentView"
                  :item="item"
                  :thumbSize="{ width: 600, height: 0 }"
                  class="blogimg"
                  :collection="collection"
                  :borderRadius="true"
                  @imageerror="OnImageError"
                  :style="!isMobile && !imageError ? 'width:300px' : ''"
                />
              </div>
              <div
                :class="{ moreLess: !showMoreLess && checkContentHeight }"
                :ref="'mark' + source._id"
              >
                <markdown-it-vue
                  class="md-body"
                  :content="source.body"
                  :collection="collection"
                />
              </div>
            </v-col>
          </v-row>
          <v-row v-if="isMobile" class="d-flex align-center vertical-align">
            <div class="pa-3 d-flex">
              <v-btn @click="openItemEditor(source)" icon class="mx-1"
                ><v-icon class="small-icon primary--text"
                  >mdi-arrow-expand</v-icon
                ></v-btn
              >
              <div
                class="mt-2 comment-box mx-1"
                @click="toggleCollapseComment = !toggleCollapseComment"
              >
                <div v-if="comments">
                  <v-icon class="small-icon primary--text"
                    >mdi-comment-text-outline</v-icon
                  ><span class="item-font">{{ comments }}</span>
                </div>
                <div v-else>
                  <v-icon class="small-icon primary--text"
                    >mdi-comment-plus-outline</v-icon
                  >
                </div>
              </div>
              <div
                class="mt-2 attachment-box mx-1"
                @click="
                  toggleCollapseAttachment = !toggleCollapseAttachment;
                  showAttachments();
                "
              >
                <div>
                  <v-icon class="small-icon primary--text"
                    >mdi-attachment</v-icon
                  ><span class="item-font" v-if="attachments">{{
                    attachments
                  }}</span>
                </div>
              </div>
            </div>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" v-if="toggleCollapseAttachment">
              <div class="mt-4 d-flex">
                <Attachment
                  :parentView="view"
                  :authorization="authorization"
                  ref="attachment"
                  :item="source"
                  :collection="collection"
                  :key="'itemEditorAttachment' + source._rev"
                  :icon="false"
                  :height="'100px'"
                />
              </div>
            </v-col>
            <v-col cols="12" v-if="toggleCollapseComment">
              <div class="mt-4 d-flex">
                <InlineComment
                  :lineThree="false"
                  :collection="collection"
                  ref="comment"
                  :item="source"
                  :key="'collapse_comment' + item._rev"
                  :authorization="authorization"
                  :showCommentText="toggleCollapseComment"
                />
              </div>
            </v-col>
          </v-row>
        </div>
      </div>
      <div class="full-width expandselected card" v-else>
        <div
          class="pa-4 blog-content navigate"
          v-outside-click="outsideSave"
          :id="source._id"
        >
          <v-row no-gutters class="blogclick" v-if="olditem !== null">
            <div class="heading-left" :class="isMobile ? '' : 'd-flex'">
              <div class="content-left">
                <input
                  autofocusitem
                  tabindex="1"
                  ref="itemTitle"
                  type="text"
                  :readonly="!authorization.update"
                  v-model="olditem.title"
                  placeholder="Title here..."
                  :class="isMobile ? 'text-center' : ''"
                  style="font-size: 1.3rem; margin-top: -2px !important"
                  class="custom-input font-weight-bold h1 text-left pa-1 mt-0"
                />
                <div v-if="source.createdBy">
                  <v-avatar size="40" color="orange accent-1">
                    <AsyncAvatarImage
                      :username="source.createdBy"
                      :collection="collection"
                    />
                  </v-avatar>
                  <span class="ml-3 title" v-if="source.createdBy">{{
                    userInfo.display
                  }}</span>
                </div>
              </div>
              <div class="content-right">
                <p
                  class="
                    px-3
                    pt-1
                    caption
                    float-none float-md-right float-sm-none
                  "
                  v-if="source.createdAt"
                >
                  <v-icon small>mdi-history</v-icon>
                  Posted on:
                  {{
                    utils.formatDateTime(acctualPostDate, "DD/MMM/YYYY hh:mm A")
                  }}
                </p>
              </div>
            </div>
            <div class="heading-right" v-if="!isMobile">
              <div class="pa-0">
                <v-btn @click="openItemEditor(source)" icon
                  ><v-icon>mdi-arrow-expand</v-icon></v-btn
                >
                <div
                  class="mt-2 comment-box"
                  @click="toggleExpandComment = !toggleExpandComment"
                >
                  <div v-if="comments">
                    <v-icon class="small-icon primary--text"
                      >mdi-comment-text-outline</v-icon
                    ><span class="item-font">{{ comments }}</span>
                  </div>
                  <div v-else>
                    <v-icon class="small-icon primary--text"
                      >mdi-comment-plus-outline</v-icon
                    >
                  </div>
                </div>
                <div class="mt-2 attachment-box" @click="showAttachments()">
                  <v-icon class="small-icon primary--text"
                    >mdi-attachment</v-icon
                  ><span class="item-font" v-if="attachments">{{
                    attachments
                  }}</span>
                </div>
              </div>
            </div>
          </v-row>
          <v-row
            tabindex="2"
            @focus="expandFocusBody()"
            no-gutters
            class="mt-4"
            style="min-height: 200px"
            v-if="expandBody"
            @click.stop="expandFocusBody()"
          >
            <v-col cols="12" sm="12" md="12" class="blogclick column">
              <div
                class="blogclick text-center mb-3"
                :class="[
                  index % 2 == 0
                    ? 'float-md-left float-sm-none margin-right'
                    : 'float-md-right float-sm-none margin-left',
                  isMobile ? 'ma-0' : '',
                ]"
                @click="openItem(source, index)"
                v-show="!imageError"
              >
                <FeatureImage
                  :attachmentView="attachmentView"
                  :item="item"
                  :thumbSize="{ width: 600, height: 0 }"
                  class="blogimg"
                  @imageerror="OnImageError"
                  :collection="collection"
                  :borderRadius="true"
                  :style="!isMobile && !imageError ? 'width:300px' : ''"
                />
              </div>
              <div :ref="'mark' + olditem._id">
                <markdown-it-vue
                  class="md-body"
                  :content="olditem.body"
                  :collection="collection"
                />
              </div>
            </v-col>
          </v-row>
          <v-row no-gutters class="mt-4" v-else>
            <v-col
              cols="12"
              sm="12"
              md="12"
              class="column"
              :class="{ reverse: index % 2 !== 0, 'd-flex': !isMobile }"
              v-show="!imageError"
            >
              <div
                :class="[
                  isMobile ? 'd-block' : 'content-right text-center',
                  imageError ? 'd-none' : '',
                ]"
                @click="openItem(source, index)"
              >
                <FeatureImage
                  :attachmentView="attachmentView"
                  :item="item"
                  :thumbSize="{ width: 600, height: 0 }"
                  class="blogimg mb-1"
                  :class="[index % 2 == 0 ? 'mar-3' : 'mal-3']"
                  @imageerror="OnImageError"
                  :collection="collection"
                  :borderRadius="true"
                  :style="!isMobile && !imageError ? 'width:300px' : ''"
                />
              </div>
              <div :class="isMobile ? '' : 'content-left'">
                <text-area
                  :tabindex="2"
                  ref="textarea"
                  :enableMarkdown="true"
                  :authorization="authorization"
                  v-model="olditem.body"
                  :autoheight="false"
                  class="smallarea item-font-blog blog"
                  :key="editmode"
                  @attachment="syncAttachment"
                  :collection="collection"
                  @submitSave="submitSave"
                />
              </div>
            </v-col>
          </v-row>
          <v-row v-if="isMobile" class="d-flex align-center vertical-align">
            <div class="pa-3">
              <v-btn @click="openItemEditor(item)" icon
                ><v-icon>mdi-arrow-expand</v-icon></v-btn
              >
              <div
                class="mt-2 comment-box"
                @click="toggleExpandComment = !toggleExpandComment"
              >
                <div v-if="comments">
                  <v-icon class="small-icon primary--text"
                    >mdi-comment-text-outline</v-icon
                  ><span class="item-font">{{ comments }}</span>
                </div>
                <div v-else>
                  <v-icon class="small-icon primary--text"
                    >mdi-comment-plus-outline</v-icon
                  >
                </div>
              </div>
            </div>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12">
              <div class="mt-4 d-flex">
                <Attachment
                  :parentView="view"
                  :authorization="authorization"
                  ref="attachment"
                  :item="item"
                  :collection="collection"
                  :key="'itemEditorAttachment' + item._rev"
                  :icon="false"
                  :height="'100px'"
                />
              </div>
            </v-col>
            <v-col cols="12" v-if="toggleExpandComment">
              <div class="mt-4 d-flex">
                <InlineComment
                  :lineThree="false"
                  :collection="collection"
                  ref="comment"
                  :item="source"
                  :key="'expand_comment' + item._rev"
                  :authorization="authorization"
                  :showCommentText="toggleExpandComment"
                />
              </div>
            </v-col>
          </v-row>
          <div @focus="autofocus" tabindex="3"></div>
        </div>
      </div>
    </DragDropFile>
  </div>
</template>
<script>
import Utility from '@/components/common/utils.js'
import FeatureImage from '@/components/FeatureImage.vue'
import AsyncAvatarImage from '@/components/AsyncAvatarImage.vue'
import TextArea from '@/components/common/markdownit/text-area.vue'
import lodash from 'lodash'
import { View } from '@/collections'
import _attachment from '@/components/common/attachment.js'
import '@/assets/styles/blog.scss'
import DragDropFile from '@/components/fileagent/DragDropFile.vue'
export default {
  name: 'BlogCard',
  components: {
    FeatureImage,
    AsyncAvatarImage,
    TextArea,
    DragDropFile,
    InlineComment: () => import('@/components/InlineComment.vue'),
    Attachment: () => import('@/components/Attachment.vue')
  },
  props: {
    editormultiple: Object,
    collection: Object,
    item: Object,
    properties: Object,
    index: Number,
    view: Object,
    authorization: Object,
    selectedId: String,
    account: String,
    collectionID: String

  },
  data () {
    return {
      toggleCollapseComment: false,
      toggleExpandComment: false,
      toggleCollapseAttachment: false,
      editmode: false,
      expand: false,
      showMore: false,
      utils: Utility,
      olditem: null,
      source: null,
      editDesc: false,
      userInfo: {},
      contentHeight: 0,
      commentView: null,
      attachmentView: null,
      toggle: false,
      imageError: false,
      attachmentInfo: [],
      expandBody: true
    }
  },
  beforeDestroy () {
    this.save()
    if (this.commentView !== null) this.commentView.close()
    if (this.attachmentView !== null) this.attachmentView.close()
  },
  async created () {
    this.commentView = await new View(this.collection).open({
      of: { container: this.item._id, type: 'comment' }
    })
    this.attachmentView = await new View(this.collection).open({
      of: { attached_to: this.item._id }
    })
  },
  updated: function () {},
  async mounted () {
    this.source = Object.assign({}, this.item)
    this.userInfo = await this.utils.getUserDetails(
      this.collection,
      this.source.createdBy
    )
    this.getContentHeight()
    this.showMore =
      this.properties.display &&
      this.properties.display.fields.displayFullDescription
    window.addEventListener('resize', this.windowSizeChanged)
    document.addEventListener('keydown', this.keyListener)
    document.addEventListener('paste', this.onPaste)
  },
  destroyed () {
    window.removeEventListener('resize', this.windowSizeChanged)
    document.removeEventListener('keydown', this.keyListener)
    document.removeEventListener('paste', this.onPaste)
  },
  computed: {
    changeSave () {
      if (
        !lodash.isEqual(this.olditem, this.source) &&
        this.authorization.update
      ) {
        return true
      }
      return false
    },
    highlighted () {
      var sel = localStorage.getItem('selected')
      if (sel !== null && sel === this.source._id) return true

      return false
    },
    isMobile () {
      return !!(
        this.$vuetify.breakpoint.name === 'sm' ||
        this.$vuetify.breakpoint.name === 'xs'
      )
    },
    comments () {
      return this.commentView !== null ? this.commentView.items.length : 0
    },
    attachments () {
      return this.attachmentView !== null
        ? this.attachmentView.items.length
        : 0
    },
    showMarkDown () {
      var r =
        this.source.hasOwnProperty('body') &&
        this.source.body != '' &&
        !this.editDesc
      return r
    },
    acctualPostDate () {
      return this.source.effectiveAt
        ? this.source.effectiveAt
        : this.source.createdAt
    },
    hasThumbnail () {
      if (this.item.space_key) {
        return true
      }
      return false
    },
    showMoreLess () {
      if (this.showMore) return true
      return false
    },
    checkContentHeight () {
      return this.contentHeight >= 250
    }
  },
  methods: {
    quickSave () {
      this.save()
      this.highlightItemSelectedAfterEnter(this.source._id)
    },
    submitSave () {
      var sel = localStorage.getItem('selected')
      if (sel !== null && sel === this.source._id && this.item !== null) {
        this.quickSave()
      }
    },
    async keyListener (e) {
      var sel = localStorage.getItem('selected')
      var x = document.getElementsByClassName('v-dialog__content--active')
      if (x.length > 0) {
      } else if (e.key === 'Enter' && sel !== null && sel === this.source._id) {
        if (typeof e.target.type === 'undefined') {
          e.preventDefault()
          if (this.editmode && this.expand) {
            // third enter hit
            // save item
            this.quickSave()
          } else if (!this.editmode && this.expand) {
            // second enter hit
            // enable edit mode and set autofocus
            this.editmode = true
          } else if (!this.editmode) {
            // first enter hit
            // expand item in non edit mode
            this.showExpand(this.source)
          }
        } else if (this.item !== null && e.target.type !== 'textarea') {
          e.preventDefault() // present browser event trigger textarea
          this.quickSave()
        }
      } else if (
        (e.keyCode === 8 || e.keyCode === 46) &&
        sel !== null &&
        sel === this.source._id &&
        typeof e.target.type === 'undefined'
      ) {
        this.removeItem(this.source)
      } else if (
        e.key === 'Escape' &&
        sel !== null &&
        this.source &&
        sel === this.source._id &&
        hasSelected(this.source)
      ) {
        if (this.changeSave && this.item) {
          const res = await this.$confirm(
            'There are some unsaved changes. Do you want to save',
            {
              title: `Save`,
              buttonSaveText: `Save`,
              buttonDiscardText: 'Discard',
              buttonCancelText: 'Cancel'
            }
          )
          if (res == 1) {
            this.expand = false
            this.save()
          } else if (res == 0) {
            this.expand = false
            Object.assign(this.$data, this.$options.data.apply(this))
            this.destroyPasteListener()
          } else {
            this.$refs.itemTitle.focus()
          }
        } else {
          this.expand = false
          Object.assign(this.$data, this.$options.data.apply(this))
          this.destroyPasteListener()
        }
      }
    },
    windowSizeChanged (e) {
      this.getMarkDownContentHeight()
    },
    getContentHeight () {
      this.$nextTick(() => {
        this.getMarkDownContentHeight()
      })
    },
    getMarkDownContentHeight () {
      var client = this.$refs['mark' + this.item._id]
      if (client) {
        this.contentHeight = client.clientHeight
      }
    },
    enableBodyEdit () {
      if (!this.authorization.updated) return
      this.editDesc = true
      var self = this
      self.$refs.itemBody.isMarkdown = false
      this.$nextTick(() => {
        self.$refs.itemBody.$refs.el.focus()
      })
    },
    openItem (item, index) {
      this.$emit('openItem', { index: index, item: item })
    },
    openItemEditor (item) {
      this.$router.push({
        name: 'list',
        params: {
          account: this.account,
          collectionID: this.collectionID,
          perspective: item._id
        }
      })
    },
    showReadMoreButton (element) {
      console.log('rerender show more')
      if (
        element !== null &&
        (element.offsetHeight < element.scrollHeight ||
          element.offsetWidth < element.scrollWidth)
      ) {
        // your element has an overflow
        // show read more button
        var elementToCheck = document.getElementById('less' + this.source._id)
        elementToCheck.style.display = 'block'
      } else {
        // your element doesn't have overflow
      }
    },
    highlightItemSelectedAfterEnter (_id) {
      setTimeout(() => {
        this.highlightItemSelected(_id)
      }, 100)
    },
    outsideSave () {
      this.save()
      var sel = localStorage.getItem('selected')
      this.highlightItemSelectedAfterEnter(sel)
    },
    async save () {
      if (this.olditem !== null) {
        var attachmentClick =
          typeof this.$refs.textarea === 'undefined'
            ? false
            : this.$refs.textarea.attachemntDialog
        if (!attachmentClick) {
          console.log('saved modified item')
          if (this.changeSave) {
            await this.view.put(this.olditem)
            if (this.attachmentInfo.length > 0) {
              await _attachment.add(
                this.olditem,
                this.attachmentInfo,
                this.collection
              )
            }
          }
          this.olditem = null
          this.attachmentInfo = []
          this.expand = false
        }
      }
      this.editmode = false
    },
    OnImageError (val) {
      this.imageError = val
    },
    hasSelected (source) {
      return document.getElementById(source._id).classList.contains('selected')
    },
    removeSelected () {
      document.getElementById(this.source._id).classList.remove('selected')
    },
    highlightSelected (item) {
      var doc = document.getElementById(item._id)
      if (doc.classList.contains('selected')) {
        setTimeout(() => {
          this.showExpand(item)
        }, 100)
      } else {
        this.highlightItem(item._id)
      }
    },
    highlightItem (_id) {
      this.highlightItemSelected(_id)
      // this.scrollPage(_id);
      this.$emit('update:selectedId', _id)
    },
    highlightItemSelected (_id) {
      localStorage.setItem('selected', _id)
      var element = document.getElementById(_id)
      var els = document.querySelectorAll('.navigate')
      for (var i = 0; i < els.length; i++) {
        els[i].classList.remove('selected')
      }
      element.classList.add('selected')
    },
    showExpand (item) {
      this.expand = true
      this.olditem = Object.assign({}, this.olditem, item)
    },
    autofocus () {
      var doc = document.querySelector('[autofocusitem]')
      doc.focus()
    },
    syncAttachment (value) {
      for (var i = 0; i < value.length; i++) {
        this.attachmentInfo.push(value[i])
      }
    },
    scrollPage (id) {
      var doc = document.getElementById('blog' + id)
      doc.scrollIntoView({
        behavior: 'auto',
        block: 'start',
        inline: 'start'
      })
    },
    expandFocusBody () {
      this.expandBody = false
      this.$nextTick(() => {
        this.$refs.textarea.$refs.el.focus()
      })
    },
    async dropText (text) {
      if (this.utils.validateUrl(text)) {
        await _attachment.putUrl(this.attachmentView, this.item, text, this)
      }
    },
    onPaste (e) {
      var sel = localStorage.getItem('selected')
      if (
        this.authorization.update &&
        e.clipboardData.files.length > 0 &&
        sel === this.source._id &&
        e.target.type !== 'textarea' &&
        e.target.type !== 'text'
      ) {
        console.debug('paste called', e.clipboardData.files)
        this.$refs.dragdrop.paste(e.clipboardData.files)
      }
    },
    destroyPasteListener () {
      document.removeEventListener('paste', this.onPaste)
    },
    showAttachments () {
      if (this.attachments === 0) {
        this.editormultiple.selItem = this.source
        this.editormultiple.click()
      }
    }
  },
  watch: {
    'properties.display.fields.displayFullDescription' (newVal) {
      this.showMore = newVal
    },
    editmode (newVal, oldVal) {
      if (newVal) {
        // only focus and highlight textbox title if  edit mode true
        this.$nextTick(() => {
          this.$refs.itemTitle.focus()
          this.$refs.itemTitle.select()
        })
      }
    }
  }
}
</script>

<style scoped>
.selected {
  height: 100%;
}
</style>
